































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { ChangeAddressFormFieldsProps } from '../ChangeAddressFormFields'
import { Inject } from '../../../../support'
import { IProfileService, ProfileServiceType } from '../../contracts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component<SavedAddressesFormFields>({
  name: 'SavedAddressesFormFields'
})
export class SavedAddressesFormFields extends Vue {
  @Inject(ProfileServiceType)
  protected readonly profileService!: IProfileService

  @Prop({ type: Array, required: true })
  public addresses!: ChangeAddressFormFieldsProps[]

  @PropSync('selectedItem', { type: Number, required: false })
  public _selectedItem!: number

  public onAddressEdit (id: string): void {
    this.$emit('edit', id)
  }

  public get hasAddresses (): boolean {
    return Array.isArray(this.addresses) && this.addresses.length > 0
  }

  public async onAddressSelect (id: number): Promise<boolean> {
    return await this.setDefaultAddress(id)
  }

  public async setDefaultAddress (id: number): Promise<boolean> {
    return await new Promise((resolve) => {
      this.profileService.updateDeliveryAddress(id, { defaultShipping: true, defaultBilling: true }).then(() => {
        resolve(true)
      }).catch(() => resolve(false))
    })
  }
}
export default SavedAddressesFormFields
