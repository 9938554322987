
























import { Component, Mixins, Watch } from 'vue-property-decorator'

import { Loader } from '../../../shared/molecules/Loader'
import { SimpleForm } from '../../../shared/molecules/Form'
import { ToastType } from '../../../shared/services'

import { ChangeAddressFormFields } from '../../molecules/ChangeAddressFormFields'

import {
  changeAddressFormValidators
} from './ChangeAddressForm.helpers'
import { ChangeAddressForm } from './index'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (original)
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<ChangeAddressFormSimple>({
  name: 'ChangeAddressFormSimple',
  components: { ChangeAddressFormFields, Loader, SimpleForm }
})
export class ChangeAddressFormSimple extends Mixins(ChangeAddressForm) {
  public readonly validatorsMap = changeAddressFormValidators

  /**
   * Adds delivery address.
   */
  public addDeliveryAddress (): Promise<number> {
    this.isSubmitted = null
    this.error = ''

    if (!this.user || !this.user.addresses || this.user.addresses.length === 0) {
      return this.profileService.storeDeliveryAddress(this.formData)
    }

    if (!this.user.addresses[0].id) {
      throw new Error('Missing id of address')
    }

    return this.profileService.updateDeliveryAddress(this.user.addresses[0].id, this.formData)
  }

  /**
   * Fetches delivery addresses
   */
  private fetchDeliveryAddresses (): void {
    try {
      if (!this.user || this.user.addresses.length === 0) {
        return
      }

      this.addresses = { ...this.user.addresses }

      // TODO after launch -> prepare many addresses
      const firstAddress = this.addresses[0]

      this.formData.firstName = firstAddress.firstName
      this.formData.lastName = firstAddress.lastName
      this.formData.company = firstAddress.company ? firstAddress.company : ''
      this.formData.city = firstAddress.city
      this.formData.postalCode = firstAddress.postalCode ? firstAddress.postalCode : ''
      this.formData.phoneNumber = firstAddress.phoneNumber
      this.formData.street = firstAddress.street
      this.formData.countryCode = firstAddress.countryCode

      // this.isEditable = this.deliveryAddresses.length === 0
    } catch (e) {
      this.setError(e as Error)
    } finally {
      this.isLoading = false
    }
  }

  public onSuccess (addressId: number): void {
    if (!this.user) {
      return
    }

    this.setUser({
      ...this.user,
      addresses: [{ ...this.formData, id: addressId }]
    })

    this.isSubmitted = true

    this.showToast(
      this.$t('front._common.saved').toString(),
      ToastType.Success
    )
  }

  public onFail (e: Error): void {
    this.setError(e)
  }

  @Watch('isLoggedInUser')
  protected async onWaitingForAuth () {
    this.fetchDeliveryAddresses()
  }
}

export default ChangeAddressFormSimple
